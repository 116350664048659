import Reactotron from "reactotron-react-js";
import { mst } from "reactotron-mst";

Reactotron.configure({
  name: "Tastify",
})
  .use(mst())
  .connect();

export default Reactotron;
