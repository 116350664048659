import {types} from "mobx-state-tree";
import {defaultSampleProps} from "../sample/default-sample.props";
import {shipmentOrderFormProps} from "../shipment-order-form";

export const sampleTransactionItemProps = {
  id: types.maybeNull(types.union(types.string, types.number)),
  sampleId: types.maybeNull(types.union(types.string, types.number)),
  sampleGrade: types.maybeNull(types.string),
  sampleName: types.maybeNull(types.string),
  sampleType: types.maybeNull(types.string),
  thirdPartyEmail: types.maybeNull(types.string),
  uniqueToken: types.maybeNull(types.string),
  isRequestedNewSample: types.maybeNull(types.boolean),
  isCreatedShipment: types.maybeNull(types.boolean),
  subOrderStatus: types.maybeNull(types.string),
  notes: types.maybeNull(types.string),
}

export const sampleProps = {
  ...sampleTransactionItemProps,
  sampleLocation: types.maybeNull(types.string),
  sampleFulfillmentType: types.maybeNull(types.string),
  isApprovalNeeded: types.maybeNull(types.boolean),
  sampleWeight: types.maybeNull(types.string),
  isRoasted: types.maybeNull(types.boolean),
  sampleWarehouseReference: types.maybeNull(types.string),
  sample: types.maybeNull(types.model({
    ...defaultSampleProps,
    contractRemoteId: types.maybeNull(types.string),
    masterId: types.maybeNull(types.union(types.string, types.number)),
  })),
  label:types.maybeNull(types.string),
  approvalStatus: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  salesforceLineItemId: types.maybeNull(types.string),
	deliveryInformation: types.maybeNull(types.string),
}

export const shipmentInformationProps = {
  ...shipmentOrderFormProps,
  trackingNumber: types.maybeNull(types.string),
  courierName: types.maybeNull(types.string),
  orderRemoteId: types.maybeNull(types.string),
  tastifyOrderNumber: types.maybeNull(types.string),
  shipmentStatus: types.maybeNull(types.string),
  requestedDate: types.maybeNull(types.string),
  thirdPartyName: types.maybeNull(types.string),
  thirdPartyEmail: types.maybeNull(types.string),
  sampleFulfillmentType: types.maybeNull(types.string),
  fulfillmentStatus: types.maybeNull(types.string),
  isSubmitted: types.maybeNull(types.boolean),
  shipmentNumber: types.maybeNull(types.string),
  estimatedTimeArrival: types.maybeNull(types.string),
  sampleOrderUniqueToken: types.maybeNull(types.string),
  sampleOrderNumber: types.maybeNull(types.string),
  additionalDeliveryInstructions: types.maybeNull(types.string),
  deliveryInformation: types.maybeNull(types.string),
  additionalInstructions: types.maybeNull(types.string),
}

export const subOrderProps = {
  id: types.maybeNull(types.union(types.string, types.number)),
  sampleLocation: types.maybeNull(types.string),
  thirdPartyEmail: types.maybeNull(types.string),
  sampleFulfillmentType: types.maybeNull(types.string),
  notes:  types.maybeNull(types.string),
  isRoasted: types.optional(types.boolean, false),
  subOrderStatus:  types.maybeNull(types.string),
}

export const sampleOrderDefault = {
  ...shipmentOrderFormProps,
  tastifyOrderNumber: types.string,
  fulfillment: types.maybeNull(types.string),
  fulfillmentStatus: types.string,
  sampleTransactionItems: types.optional(
    types.array(types.model(sampleTransactionItemProps)),
    []
  ),
  numberOfSamples: types.number,
  uniqueToken: types.string,
  createdAt: types.string,
  isSubmitted: types.boolean,
  orderNumber: types.maybeNull(types.string),
  isRequestedNewSample: types.boolean,
  country: types.maybeNull(types.string),
  customer: types.maybeNull(types.string),
  purchaseContractReference: types.maybeNull(types.string),
  salesContractReference: types.maybeNull(types.string),
  sampleOrderNumber: types.maybeNull(types.string),
  warehouses: types.maybeNull(types.string),
  subOrder: types.map(types.array(types.model(subOrderProps)))
}
