/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withGeneralError, withReset, withRootStore, withEnvironment, withSetValue
} from "models/extensions"
import {
  ShipmentOrderFormModel,
  shipmentOrderFormPreProcessor,
  shipmentOrderFormProps
} from "models/shipment-order-form";

import {
  sampleItemProps,
  sampleProps,
  sampleShipmentDefault,
  sampleTransactionItemProps,
  shipmentInformationProps,
} from "./sample-shipment.props"
import {withSampleShipmentActions} from "./sample-shipment-action";
import {withSampleShipmentViews} from "./sample-shipment.view";
import {filterModel, filterModelCancelled, filterModelFulfilled, filterModelInProgress, filterModelUnfulfilled} from "./filter-model";
import {SampleShipmentScoreModel} from "./shipment-score";

/**
 * Model description here for TypeScript hints.
 */
export const SampleShipmentStoreModel = types
  .model("SampleShipmentStore")
  .props({
    samplesToBeShipment: types.optional(
      types.array(types.model(sampleProps)),
      []
    ),
    shipmentForm: types.optional(ShipmentOrderFormModel, shipmentOrderFormPreProcessor({})),
    shipmentInformation: types.optional(types.model(shipmentInformationProps), shipmentOrderFormPreProcessor({})),
    sampleShipments: types.optional(types.array(types.model(sampleShipmentDefault)), []),
    sampleShipmentInProgress: types.optional(types.array(types.model(sampleShipmentDefault)), []),
    sampleShipmentCancelled: types.optional(types.array(types.model(sampleShipmentDefault)), []),
    sampleShipmentUnfulfilled: types.optional(types.array(types.model(sampleShipmentDefault)), []),
    sampleShipmentFulfilled: types.optional(types.array(types.model(sampleShipmentDefault)), []),
    thirdPartyName: types.optional(types.string, ''),
    thirdPartyEmail: types.optional(types.string, ''),
    archivedAt: types.optional(types.string, ''),
    fulfillmentStatus: types.optional(types.string, ''),
    page: types.optional(types.number, 1),
    totalPage: types.optional(types.number, 0),
    sort: types.optional(types.string, ''),
    filter: types.optional(filterModel, {}),
    score: types.optional(SampleShipmentScoreModel, {}),
    filterInProgress: types.optional(filterModelInProgress, {}),
    filterCancelled: types.optional(filterModelCancelled, {}),
    filterUnfulfilled: types.optional(filterModelUnfulfilled, {}),
    filterFulfilled: types.optional(filterModelFulfilled, {}),
    pageInProgress: types.optional(types.number, 1),
    totalPageInProgress: types.optional(types.number, 0),
    pageCancelled: types.optional(types.number, 1),
    totalPageCancelled: types.optional(types.number, 0),
    pageUnfulfilled: types.optional(types.number, 1),
    totalPageUnfulfilled: types.optional(types.number, 0),
    pageFulfilled: types.optional(types.number, 1),
    totalPageFulfilled: types.optional(types.number, 0),
  })
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withEnvironment)
  .extend(withSampleShipmentActions)
  .extend(withSampleShipmentViews)

type SampleShipmentStoreType = Instance<typeof SampleShipmentStoreModel>
export interface SampleShipmentStore extends SampleShipmentStoreType {}
type SampleShipmentStoreSnapshotType = SnapshotOut<typeof SampleShipmentStoreModel>
export interface SampleShipmentStoreSnapshot extends SampleShipmentStoreSnapshotType {}
