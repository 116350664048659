import React, {useCallback, useEffect, useState} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {Box, Card, Chip, Collapse, IconButton, Paper, Stack, Typography} from '@mui/material';
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import SvgIcon from '@mui/material/SvgIcon';

import { colors } from 'assets'
import {debounce, isEmpty} from 'lodash'
import {optionsTranslation} from "utils";
import { IconRoasted } from 'assets/images';

import {KanbanPageProps} from "./kanban-page.props";

  type ColumnType = {
    name: string;
    items: any[]; // Replace `any` with the specific type of your data objects
  };
  
  type ColumnsType = {
    inProgress: ColumnType;
    cancelled: ColumnType;
    unfulfilled: ColumnType;
    fulfilled: ColumnType;
  };

  const defaultColumns = {
    inProgress: {
      name: 'In Progress',
      items:[],
    },
    cancelled: {
      name: 'Cancelled',
      items: [],
    },
    unfulfilled: {
      name: 'Unfulfilled',
      items: [],
    },
    fulfilled: {
      name: 'Fulfilled',
      items: [],
    },
  }

const KanbanBoard: React.FC<KanbanPageProps> = (props: KanbanPageProps) => {

  const {
    dataInProgress, 
    onReloadInProgress, 
    dataCancelled, 
    onReloadCancelled, 
    dataUnfulfilled, 
    onReloadUnfulfilled, 
    dataFulfilled, 
    onReloadFulfilled,
    totalPageInProgress,
    totalPageCancelled,
    totalPageUnfulfilled,
    totalPageFulfilled,
    filter
  } = props

  const [columns, setColumns] = useState<ColumnsType>(defaultColumns);
  const [selectedItems, setSelectedItems] = useState({}); 
  const [expandedItems, setExpandedItems] = useState({});
  const [pageDataInProgress, setPageDataInProgress] = useState(1);
  const [pageDataCancelled, setPageDataCancelled] = useState(1);
  const [pageDataUnfulfilled, setPageDataUnfulfilled] = useState(1);
  const [pageDataFulfilled, setPageDataFulfilled] = useState(1);
  const [columnIdTmp, setColumnIdTmp] = useState("");
  const [loadingState, setLoadingState] = useState<Record<string, boolean>>({});

  const buildColumns = useCallback(() => ({
    inProgress: { name: 'In Progress', items: dataInProgress },
    cancelled: { name: 'Cancelled', items: dataCancelled },
    unfulfilled: { name: 'Unfulfilled', items: dataUnfulfilled },
    fulfilled: { name: 'Fulfilled', items: dataFulfilled },
  }), []);

  const setDataIncrementPage = async (columnId: string) => {
    try {
        switch (columnId) {
            case "unfulfilled":
                setPageDataUnfulfilled((prevPage) => prevPage >= (totalPageUnfulfilled + 1) ? prevPage : prevPage + 1 )
                break
            case "cancelled":
                setPageDataCancelled((prevPage) => prevPage >= (totalPageCancelled + 1) ? prevPage : prevPage + 1 )
                break
            case "fulfilled":
                setPageDataFulfilled((prevPage) => prevPage >= (totalPageFulfilled + 1) ? prevPage : prevPage + 1 )
                break
            default:
                setPageDataInProgress((prevPage) => prevPage >= (totalPageFulfilled + 1) ? prevPage : prevPage + 1 )
                break
        }
    } catch (error) {
        console.error("Error loading data:", error);
    } finally {
    
    }
  }

  const reloadData = async (columnId: string) => {
    try {
        switch (columnId) {
            case "unfulfilled":
                await onReloadUnfulfilled(pageDataUnfulfilled)
                break
            case "cancelled":
                await onReloadCancelled(pageDataCancelled)
                break
            case "fulfilled":
                await onReloadFulfilled(pageDataFulfilled)
                break
            default:
                await onReloadInProgress(pageDataInProgress)
                break
        }
    } catch (error) {
        console.error("Error loading data:", error);
    } finally {
        setLoadingState((prev) => ({ ...prev, [columnId]: false }));
    }
  }


  const loadMoreItems = (columnId) => {
    if (filter || loadingState[columnId]) return; // Prevent multiple simultaneous fetches for the same column

    setColumnIdTmp(columnId);
    
    const currentPage = {
      inProgress: pageDataInProgress,
      cancelled: pageDataCancelled,
      unfulfilled: pageDataUnfulfilled,
      fulfilled: pageDataFulfilled,
    }[columnId];

    const totalPage = {
      inProgress: totalPageInProgress,
      cancelled: totalPageCancelled,
      unfulfilled: totalPageUnfulfilled,
      fulfilled: totalPageFulfilled,
    }[columnId];

    if (currentPage >= (totalPage + 1)) return; // Stop loading
  

    // Set loading to true for the column
    setLoadingState((prev) => ({ ...prev, [columnId]: true }));

    setDataIncrementPage(columnId)
  };

  const toggleExpanded = useCallback((id: string) => {
    setExpandedItems((prev) => ({ ...prev, [id]: !prev[id] }));
  }, []);


  const handleScroll = (e, columnId) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (scrollTop + clientHeight >= scrollHeight - 5) {
      loadMoreItems(columnId);
    }
  };

  const toggleSelection = (columnId, itemId) => {
    setSelectedItems({});
    setSelectedItems((prev) => {
      const columnSelections = prev[columnId] || new Set();
      const updatedSelections = new Set(columnSelections);

      if (updatedSelections.has(itemId)) {
        updatedSelections.delete(itemId);
      } else {
        updatedSelections.add(itemId);
      }

      return { ...prev, [columnId]: updatedSelections };
    });


  };

  const isItemExpanded = (itemId: string): boolean => {
    return Object.entries(expandedItems)
      .filter(([_, flag]) => flag)
      .map(([id]) => id)
      .includes(itemId);
  };

  const renderRoasted = (data) => {
    return(
      <SvgIcon component={IconRoasted} fontSize="small" sx={{ color: !data ? 'red' : "green" }} inheritViewBox />
    )
  }

  const renderSubOrder = (items, index) => {
    if (!items?.subOrder || items.subOrder.size === 0) return null;

    // Generate for each subOrder
    const renderData = Array.from({ length: items.subOrder.size }).map((_, i) => {
      const alphabet = String.fromCharCode(65 + i); // 65 is the ASCII code for 'A'
      const data = items?.subOrder?.get?.(i);

      if (!data) return null

      // Filter and map data to elements
      const mappedItems = data?.filter((item) => item.sampleLocation !== null) // Exclude null sampleLocation
        .map((item, idx) => (
          <>
            <Box
              key={`${items.id}-${i}-${idx}`}
              sx={{
                p: 1,
                my: 1,
                borderRadius: 1,
                flexWrap: 'wrap',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                backgroundColor: 'white'
              }}
            >
              {Object.entries(item).map(([key, value]) => {

                if (key === "sampleLocation") {
                  return (
                    <Box>
                      <Typography
                        key={key}
                        variant="body2"
                        color="primary"
                      >
                        {value}
                      </Typography>
                    </Box>
                  )
                }
                if (key === "isRoasted") {
                  return (
                    <Box>
                      <Typography
                        key={key}
                        variant="body2"
                        color="primary"
                      >
                        {renderRoasted(value)}
                      </Typography>
                    </Box>
                  )
                }
                return null
              })}
            </Box>
          </>
        ));

        const mappedNotes = data?.filter((item) => item.sampleLocation !== null) // Exclude null sampleLocation
        .map((item, idx) => {

          if(idx > 0) return

          return(
          <>
            <Box
              key={`${items.id}-${i}-${idx}`}
            >
              {Object.entries(item).map(([key, value]) => (
                <Typography
                  key={key}
                  variant="subtitle2"
                  color={colors.text.o50}
                >
                  {key === "notes" ? value : null}
                </Typography>
              ))}
            </Box>
          </>
        )});

      return (
        <Box key={`${items.id}-${i}`} sx={{ p: 1, mt: 1 }}>
          <Typography variant="body2" color="primary">
            {items.sampleOrderNumber}-{alphabet}
          </Typography>
          <Typography variant="body2" color="primary">
            {optionsTranslation('fulfillmentType', data?.[0]?.sampleFulfillmentType || "N/A")}{isEmpty(data?.[0]?.subOrderStatus) ? "" : ` - ${optionsTranslation('fulfillmentStatus',data?.[0]?.subOrderStatus)}`}
          </Typography>
          {mappedItems}
          {mappedNotes}
        </Box>
      );
    });

    return <Box>{renderData}</Box>
  };


  const onDragEnd = (result) => {
    // if (!result.destination) return;

    // const { source, destination } = result;

    // if (source.droppableId === destination.droppableId) {
    //   const column = columns[source.droppableId];
    //   const copiedItems = [...column.items];
    //   const [removed] = copiedItems.splice(source.index, 1);
    //   copiedItems.splice(destination.index, 0, removed);

    //   setColumns({
    //     ...columns,
    //     [source.droppableId]: { ...column, items: copiedItems },
    //   });
    // } else {
    //   const sourceColumn = columns[source.droppableId];
    //   const destColumn = columns[destination.droppableId];
    //   const sourceItems = [...sourceColumn.items];
    //   const destItems = [...destColumn.items];
    //   const [removed] = sourceItems.splice(source.index, 1);
    //   destItems.splice(destination.index, 0, removed);

    //   setColumns({
    //     ...columns,
    //     [source.droppableId]: { ...sourceColumn, items: sourceItems },
    //     [destination.droppableId]: { ...destColumn, items: destItems },
    //   });
    // }
  };

  const setDataColumns = (columnId, currentItems) => {

    const dataMap = {
      inProgress: dataInProgress,
      cancelled: dataCancelled,
      unfulfilled: dataUnfulfilled,
      fulfilled: dataFulfilled,
    };
  
    const newItems = dataMap[columnId]?.filter(
      (newItem) => !currentItems.some((currentItem) => currentItem.id === newItem.id)
    ) || [];
  
    return newItems;
  }

  const setDefaultData = async() => {
    setPageDataCancelled(1)
    setPageDataFulfilled(1)
    setPageDataUnfulfilled(1)
    setPageDataInProgress(1)
    setColumns(buildColumns())
  }

  useEffect(() => {
    if(isEmpty(columnIdTmp) || filter) return;

    const debounceUpdate = debounce(async () => {
      try {
        await reloadData(columnIdTmp)

        setColumns((prev) => {
          const currentItems = prev[columnIdTmp].items;
          const newItems = setDataColumns(columnIdTmp, currentItems)
      
          return {
            ...prev,
            [columnIdTmp]: {
              ...prev[columnIdTmp],
              items: [...currentItems, ...newItems],
            },
          };
        });
      } finally {
        // Set loading to true for the column
        setLoadingState((prev) => ({ ...prev, [columnIdTmp]: false }));
      }
    }, 1500)

    debounceUpdate();
    return debounceUpdate.cancel;
    
  }, [
    columnIdTmp,
    pageDataInProgress,
    pageDataCancelled,
    pageDataUnfulfilled,
    pageDataFulfilled,
  ]);

  useEffect(() => {
    setDefaultData()
  }, []);

  useEffect(() => {
    
    const debounceUpdate = debounce(async () => {
      try {
        await setDefaultData()
      } finally {
        // Set loading to true for the column
        setLoadingState((prev) => ({ ...prev, [columnIdTmp]: false }));
      }
    }, 1500)

    debounceUpdate();
    return debounceUpdate.cancel;
    
  }, [filter]);

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      isDragDisabled={true} // Disable dragging
    >
      <Box display="flex" gap={2} p={2} overflow="auto" >
        {Object.entries(columns).map(([columnId, column]) => (
          <Droppable
            key={columnId}
            droppableId={columnId}
            isDropDisabled={true}
          >
            {(provided) => (
              <Paper
                ref={provided.innerRef}
                {...provided.droppableProps}
                elevation={3}
                sx={{
                  width: 400,
                  maxHeight: '80vh',
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: colors.border.primary,
                  cursor: 'default', // Change cursor to default
                  pointerEvents: loadingState[columnId]  ? "none" : "auto", // Disable interactions when loading
                  opacity: loadingState[columnId]  ? 0.5 : 1, //Visual cue for disabled state
                }}
                onScroll={(e) => handleScroll(e, columnId)}
              >
                <Box
                  sx={{
                    position: "sticky",
                    display: 'flex',
                    flexDirection: "row",
                    justifyContent: "flex-start", // Align items to the left
                    alignItems: "center", // Align vertically
                    top: 0,
                    zIndex: 200,
                    backgroundColor: colors.border.primary,
                    padding: 2,
                    gap: 1,
                  }}
                >
                  <Typography variant="h6">
                    {column.name} {/* Column name with proportional spacing */}
                  </Typography>
                  <Chip
                    label={
                      <Typography variant="body2" sx={{ textAlign: "center", width: "100%" }}>
                        {column.items.length}
                      </Typography>
                    }
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "50%",
                      width: 37,
                      height: 37,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minWidth: "auto",
                    }}
                  />
                </Box>       
                <Box>
                  {column.items.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided) => (
                        <Paper
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{ p: 1, m: 1, background: '#f5f5f5' }}
                          onClick={() => toggleSelection(columnId, item.id)}
                        >
                          <Stack direction="row" justifyContent="space-between">
                            <Box sx={{flexDirection: 'column'}}>
                              <Typography variant="body1" color={colors.primary.main}>{isEmpty(item.shipmentNumber) ? item.sampleOrderNumber : item.shipmentNumber}{isEmpty(item.country) ? "" : ` - ${item.country}`} </Typography>
                              <Typography variant="body2">{item.customer}</Typography>
                           </Box>
                           {isEmpty(item.shipmentNumber) &&
                             <Box>
                             <IconButton
                               size="medium"
                               onClick={() => toggleExpanded(item.id)}
                               sx={{ p: 0, mt: 1 }}
                             >
                               {isItemExpanded(item.id) ? <ExpandLess /> : <ExpandMore />}
                             </IconButton>
                           </Box>
                           }
                          </Stack>
                          <Collapse in={isItemExpanded(item.id)} timeout="auto" unmountOnExit sx={{ p: 1 }}>
                            <Card sx={{ backgroundColor: colors.border.primary}}>
                              {isEmpty(item.shipmentNumber) && renderSubOrder(item, index)}
                            </Card>
                          </Collapse>

                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {loadingState[columnId] && (
                      <Box display="flex" justifyContent="center" alignItems="center" py={2}>
                        <Typography variant="body2" color="primary">
                          Loading...
                        </Typography>
                      </Box>
                    )}
                  {provided.placeholder}
                </Box>
              </Paper>
            )}
          </Droppable>
        ))}
      </Box>
    </DragDropContext>
  );
};

export default KanbanBoard;

