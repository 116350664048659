import React, {useEffect, useState, useRef} from 'react'
import {observer} from "mobx-react-lite";
import {useParams, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {cloneDeep, isNull, has} from "lodash";
import {isMobile} from "react-device-detect";
import {
  Accordion, AccordionDetails, AccordionSummary,
  Box,
  Button,
  Container, Divider,
  IconButton,
  Stack,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import {
  EmailOutlined,
  ExpandLess,
  ExpandMore,
  Feed,
  Edit as EditIcon,
  SaveOutlined,
  DriveFolderUpload, SyncAlt,
} from "@mui/icons-material";
import {
  createColumnHelper,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from "@tanstack/react-table";

import {useStores} from "models";
import {globalAction, optionsTranslation, str2bool} from "utils";
import {fulfillmentTypes} from "constants/form"
import {BasicTable, HeaderTitle, ModalMessage, ModalWrapper, SubmitOrder, HeaderTitleWide} from "components";
import {SampleFulfillmentType, SampleShipmentApproval, SampleShipmentFulfillmentStatus} from "constants/constant";
import {PrintLabel} from "pages/modal";
import {ApproveReject, ApproveRejectButton, SampleInformationModal} from "modules";
import {IconStartCupping} from "assets/images";
import { ShipmentInformation, ShipmentInfoModal } from "modules";
import {colors} from "assets";

import {useStyles} from "./show.styles";
import {SendEmailModal, ShipmentFulfillModal } from "../index";

const columnHelper = createColumnHelper<any>()

export const ShowShipmentForm = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const params = useParams()
  const navigate = useNavigate()

  const {
    companyStore: { name: companyName },
    sampleShipmentStore: {
      samplesToBeShipment, addSampleShipments, shipmentInformation, getSampleShipment, setShipmentForm,
      sendEmailSubmitTheOrder, updateSampleShipment, setSamplesToBeShipment, setSamplesToBeShipmentSample,
      setValue: setSampleShipmentsValue
    },
    notificationStore
  } = useStores()

  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [openConfirmApprove, setOpenConfirmApprove] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [openQcReason, setOpenQcReason] = useState(false)
  const [openShipmentFulfillModal, setOpenShipmentFulfillModal] = useState(false)
  const [openShipmentInfoModal, setOpenShipmentInfoModal] = useState(false)
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [openPrintLabel, setOpenPrintLabel] = useState(false)
  const [openSubmitOrder, setOpenSubmitOrder] = useState(false)
  const [descriptionSuccess, setDescriptionSuccess] = useState('')
  const [transactionItemId, setTransactionItemId] = useState('')
  const [indexSamples, setIndexSamples] = useState(-1)
  const [selectedSample, setSelectedSample] = useState(0)
  const [changedStatus, setChangedStatus] = useState({})
  const [openSampleModal, setOpenSampleModal] = useState(false)
  const [data, setData ] = useState(cloneDeep(samplesToBeShipment))
  const fulfilled = shipmentInformation.fulfillmentStatus === SampleShipmentFulfillmentStatus.FULFILLED
  const isRequestNewSample = shipmentInformation.fulfillmentStatus === SampleShipmentFulfillmentStatus.NEW_REQUEST
  const refInput = useRef('')
  const isEdit = false

  const resetRefInput = () => {
    refInput.current = ''
  }

  const onSetDataValue = (rowIndex, key, value) => {
	  setData(old => {
		  const newData = [...old]
		  let currentData = newData[rowIndex]

		  if (has(currentData, key)) currentData[key] = value
		  if (has(currentData, `sample.${key}`)) currentData.sample[key] = value
		  if (key === 'sampleFulfillmentType' && value === fulfillmentTypes[0]) {
				currentData.sampleLocation = companyName
		  }

			newData[rowIndex] = currentData
		  return newData
	  })
  }

  const renderSubComponent = ({ row }) => {
    const {isRoasted, sample, approvalStatus, reason, sampleFulfillmentType  } = row.original
    const showReason = approvalStatus === SampleShipmentApproval.REJECTED || approvalStatus === SampleShipmentApproval.DECLINED
    const reasonLabel = approvalStatus === SampleShipmentApproval.REJECTED ? t('shipment.reasonWasRejected') : t('shipment.reasonWasDeclined')

    const items = [
      ...(isRequestNewSample ? [
        { label: t('shipment.table.salesContract'), value: sample.salesContractReference },
        { label: t('shipment.table.purchaseContract'), value: sample.purchaseContractReference },
      ] : [
        { label: t('shipment.table.country'), value: sample.country }
      ]),
      { label: t('shipment.table.fulfillmentType'), value: sampleFulfillmentType },
      { label: t('shipment.table.warehouse'), value: sample.warehouseReference },
      { label: t('shipment.table.producer'), value: sample.producerName },
      { label: t('shipment.table.salesContract'), value: sample.salesContractReference },
      { label: t('shipment.table.processing'), value: sample.processName },
    ]

    return (
      <Box className={classes.collapseWrapper}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          {items.map(item => {
            const val = item.value ? item.value : '-'
            return (
              <Box>
                <Typography variant="subtitle1">{item.label}</Typography>
                <Typography variant="body2">{val}</Typography>
              </Box>
            )
          })}
        </Stack>

        {showReason && (
          <Box mt={2}>
            <Typography variant="subtitle1">{reasonLabel}</Typography>
            <Typography variant="body2">{reason ?? '-'}</Typography>
          </Box>
        )}
      </Box>
    )
  }

  const renderApprovalButton = (row, index) => {
    const edited = changedStatus[index]

    return (
      <ApproveRejectButton
        row={row}
        edited={edited}
        onApprove={(hasApproval) => {
          setTransactionItemId(row.uniqueToken)
          if (hasApproval) setOpenQcReason(true)
          setOpenConfirmApprove(true)
          setChangedStatus({})
        }}
        onReject={(hasApproval) => {
          setTransactionItemId(row.uniqueToken)
          if (hasApproval) setOpenQcReason(true)
          setOpenRejectModal(true)
          setChangedStatus({})
        }}
        buttonProps={isMobile ? {fullWidth: true} : {}}
        boxProps={{sx: {minWidth: isMobile ? '100%' : 'fit-content'}}}
      />
    )
  }

  const renderActionRowTable = (row, index, children?) => {
    const declined = row.approvalStatus === SampleShipmentApproval.DECLINED
    const isNullApprovalStatus = isNull(row.approvalStatus)

    return (
      <Stack direction={isMobile ? "column" : "row"} spacing={1} justifyContent="center" sx={{mt: isMobile ? 2 : 0}}>
        <Stack direction="row" spacing={1}>
          <Button
            fullWidth={isMobile}
            variant="contained"
            size="small"
            sx={{p: 0.5, minWidth: 'fit-content'}}
            onClick={() => {
              setIndexSamples(index)
              setOpenPrintLabel(true)
            }}
          >
            <Feed />
            {isMobile && <Typography sx={{ml: 0.5}} variant="body2">{t('shipment.button.generateLabel')}</Typography>}
          </Button>
        </Stack>
        <Stack direction="row" spacing={1}>
          {renderApprovalButton(row, index)}
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button
            fullWidth={isMobile}
            size="small"
            variant="contained"
            sx={{minWidth: '1px'}}
            disabled={declined}
            onClick={() => navigate(`/sample-shipments/${params.id}/item/${row.uniqueToken}/score`)}
          >
            <IconStartCupping fill={declined ? "rgba(0, 0, 0, 0.26)" : "white"} width={24} height={24} />
            {isMobile && <Typography sx={{ml: 0.5}} variant="body2">{t('shipment.button.cupping')}</Typography>}
          </Button>
          <Button
            fullWidth={isMobile}
            size="small"
            variant="contained"
            sx={{minWidth: '1px'}}
            onClick={() => {
              setSelectedSample(parseInt(index))
              setOpenSampleModal(true)
            }}
          >
            <EditIcon />
            {isMobile && <Typography sx={{ml: 0.5}} variant="body2">{t('common.edit')}</Typography>}
          </Button>
          <Button
            fullWidth={isMobile}
            size="small"
            variant="contained"
            sx={{minWidth: '1px'}}
            disabled={declined || isNullApprovalStatus}
            onClick={() => {
              setChangedStatus({[index]: !changedStatus[index]})
            }}
          >
            <SyncAlt />
            {isMobile && <Typography sx={{ml: 0.5}} variant="body2">{t('shipment.button.change')}</Typography>}
          </Button>
        </Stack>
        {children}
      </Stack>
    )
  }

  const columns = [
    columnHelper.accessor('sampleType', {
      id: 'sampleType',
      header: ({ table }) =>
        <Box textAlign="left">
          <FormControlLabel
            label={t('shipment.table.type')}
            control={
              <Checkbox
	              disabled={isEdit}
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
            }
          />
        </Box>,
      cell: info =>
	      <Box textAlign="left">
		      <FormControlLabel
			      label={optionsTranslation('sample.type', info.renderValue(), true)}
			      control={
				      <Checkbox
					      disabled={isEdit}
					      checked={info.row.getIsSelected()}
					      onChange={info.row.getToggleSelectedHandler()}
				      />
			      }
		      />
	      </Box>
    }),
    columnHelper.accessor('sample.purchaseContractReference', {
      id: 'sample.purchaseContractReference',
      header: () => <Box textAlign="left">{t('shipment.table.purchaseContract')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('sample.purchaseGrade', {
      id: 'sample.purchaseGrade',
      header: () => <Box textAlign="left">{t('shipment.table.purchaseGrade')}</Box>,
      cell: info => optionsTranslation('grades', info.renderValue()),
    }),
    columnHelper.accessor('sampleLocation', {
      id: 'sampleLocation',
      header: () => <Box textAlign="left">{t('shipment.table.sampleLocation')}</Box>,
      cell: info => optionsTranslation('sampleType', info.renderValue()),
    }),
    columnHelper.accessor('isRoasted', {
      id: 'isRoasted',
      header: () => t('shipment.table.roasted'),
      cell: info => isEdit ?
        <Select
          size='small'
          value={info.renderValue()}
          onChange={(e) => {
            resetRefInput()
            onSetDataValue(info.row.index,'isRoasted', str2bool(e.target.value))
          }}
        >
          {[true, false].map(bool =>
            <MenuItem key={bool.toString()} value={bool.toString()}>
              {optionsTranslation('boolean', bool.toString())}
            </MenuItem>
          )}
        </Select> :
        <Box textAlign="center">
          {optionsTranslation('boolean', info.renderValue()?.toString())}
        </Box>,
    }),
    columnHelper.accessor('isApprovalNeeded', {
      id: 'isApprovalNeeded',
      header: () => <Box textAlign="left">{t('shipment.table.approvalNeeded')}</Box>,
      cell: info => isEdit ?
        <Select
          size='small'
          value={info.renderValue()}
          onChange={(e) => {
            resetRefInput()
            onSetDataValue(info.row.index,'isApprovalNeeded', str2bool(e.target.value))
          }}
        >
          {[true, false].map(bool =>
            <MenuItem key={bool.toString()} value={bool.toString()}>
              {optionsTranslation('boolean', bool.toString())}
            </MenuItem>
          )}
        </Select> :
        <Box textAlign="center">
          {optionsTranslation('boolean', info.renderValue()?.toString())}
        </Box>,
    }),
    columnHelper.accessor('sampleWeight', {
      id: 'sampleWeight',
      header: () => <Box textAlign="left">{t('shipment.table.weight')}</Box>,
      cell: info => optionsTranslation('sampleType', info.renderValue()),
    }),
    columnHelper.accessor('expand', {
      id: 'expand',
      header: () => t('shipment.table.action'),
      cell: info => {
        return renderActionRowTable(
          info.row.original,
          info.row.id,
          <IconButton
            size="small"
            color="primary"
            onClick={info.row.getToggleExpandedHandler()}
          >
            {info.row.getIsExpanded() ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )
      }
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    state: { expanded },
    manualExpanding: true,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: row => row.id,
    getCoreRowModel: getCoreRowModel(),
  })

  const renderButtons = (
    <Stack direction={isMobile ? "column" : "row"} spacing={1} useFlexGap flexWrap="wrap" width={isMobile ? "100%" : "fit-content"}>
      <Stack direction="row" spacing={1}>
        {shipmentInformation.sampleFulfillmentType?.toLowerCase() === SampleFulfillmentType.THIRD_PARTY && (
          <Button
            fullWidth={isMobile}
            variant="contained"
            color="secondary"
            disabled={shipmentInformation.isSubmitted}
            onClick={() => setOpenSubmitOrder(true)}
            startIcon={<DriveFolderUpload />}
          >
            {t('shipment.button.submit')}
          </Button>
        )}
        <Button
          fullWidth={isMobile}
          variant="contained"
          color="secondary"
          disabled={fulfilled}
          onClick={() => setOpenShipmentFulfillModal(true)}
          startIcon={<SaveOutlined />}
        >
          {fulfilled ? t('options.fulfillmentStatus.fulfilled') : t('shipment.button.fulfill')}
        </Button>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Button
          fullWidth={isMobile}
          variant="contained"
          color="primary"
          startIcon={<EmailOutlined />}
          onClick={() => setOpenEmailModal(true)}
        >
          {t('shipment.button.send')}
        </Button>
        <Button
          fullWidth={isMobile}
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
          onClick={() => setOpenShipmentInfoModal(true)}
        >
          {t('common.edit')}
        </Button>
      </Stack>
    </Stack>
  )

  const onSubmitTheOrder = async () => {
    try {
      setOpenSubmitOrder(false)
      notificationStore.setLoading(true)
      await sendEmailSubmitTheOrder(params.id)
      setDescriptionSuccess(t('shipment.success.successfullySubmitOrder'))
      setOpenSuccess(true)
    } finally {
      notificationStore.setLoading(false)
    }
  }

  const onSubmitSampleShipment = async () => {
    try {
      notificationStore.setLoading(true)
      await updateSampleShipment(params.id)

      setDescriptionSuccess(t('shipment.success.successfullyUpdatedShipment'))
      setOpenSuccess(true)
    } finally {
      notificationStore.setLoading(false)
    }
  }

  const handlerRequestNewShipment = (info) => {
    setShipmentForm()
    addSampleShipments(info.row.id)

    navigate(`/sample-shipments/${shipmentInformation.fulfillmentStatus}`)
  }

  const renderSampleInformationMobile = (
    <Box>
      {data.map((row, index) => (
        <Accordion key={row.uniqueToken} className="accordion">
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Stack direction="row" spacing={1}>
              <Box>
                <Typography variant='h6'>{row.sampleName}</Typography>
                <Stack
                  direction="row"
                  spacing={0.5}
                  divider={<Divider orientation="vertical" flexItem sx={{borderColor: "#999FA4"}} />}
                >
                  <Typography variant="body2" color={colors.text.caption}>{row.sample.processName || '-'}</Typography>
                  <Typography variant="body2" color={colors.text.caption}>{row.sample.purchaseContractReference || '-'}</Typography>
                </Stack>
              </Box>
            </Stack>
          </AccordionSummary>
          <AccordionDetails className="content">
            <table cellPadding={5} className="mobile-content">
              <tbody>
                <tr>
                  <td>{t('shipment.table.sampleGrade')}</td>
                  <td>{optionsTranslation('grades', row.sampleGrade, true)}</td>
                </tr>
                <tr>
                  <td>{t('shipment.table.fulfillmentType')}</td>
                  <td>{optionsTranslation('fulfillmentType', row.sampleFulfillmentType, true)}</td>
                </tr>
                <tr>
                  <td>{t('shipment.table.salesContract')}</td>
                  <td>{row.sample.salesContractReference || '-'}</td>
                </tr>
                <tr>
                  <td>{t('shipment.table.purchaseContract')}</td>
                  <td>{row.sample.purchaseContractReference || '-'}</td>
                </tr>
                <tr>
                  <td>{t('shipment.table.containerNumber')}</td>
                  <td>{row.sample.containerNumber || '-'}</td>
                </tr>
                <tr>
                  <td>{t('shipment.table.lotNumber')}</td>
                  <td>{row.sample.lotNumberOrIcoMarks || '-'}</td>
                </tr>
              </tbody>
            </table>

            {renderActionRowTable(row, index)}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )

  const renderWeb = (
    <Box>
      <Typography variant='h5'>{t('shipment.generalInformationForm')}</Typography>
      <Typography variant='body2' color="primary" mb={2}>
        {t('shipment.type')}: {optionsTranslation('fulfillmentType', shipmentInformation.sampleFulfillmentType)}
      </Typography>

      <Box className={classes.wrapper}>
        <ShipmentInformation shipmentInformation={shipmentInformation} />
      </Box>
      <Typography variant='h5' mt={3} mb={3}>{t('shipment.sampleInformation')}</Typography>

      <Box className={classes.wrapper}>
        <Typography variant='h6'>
          {t('shipment.table.sample', { count: samplesToBeShipment.length })}
        </Typography>

        <BasicTable table={table} renderSubComponent={renderSubComponent} />
      </Box>
    </Box>
  )

  const renderMobile = (
    <>
      <Accordion className="accordion">
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant='h5'>{t('shipment.generalInformationForm')}</Typography>
        </AccordionSummary>
        <AccordionDetails className="content">
          <ShipmentInformation shipmentInformation={shipmentInformation} />
        </AccordionDetails>
      </Accordion>

      <Accordion className="accordion">
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant='h5'>{t('shipment.sampleInformation')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {renderSampleInformationMobile}
        </AccordionDetails>
      </Accordion>
    </>
  )

  const onGetSampleShipment = () => {
    globalAction(notificationStore, {
      action: async () => {
        const result = await getSampleShipment(params.id)
        setData(result)
      },
    })
  }

  const onUpdateSample = (sample) => {
    setSamplesToBeShipmentSample(selectedSample, sample)
    const newData = data.map((item, index) => {
      if (index === selectedSample) {
        return {
          ...item,
          sampleName: sample.name,
          sampleGrade: sample.grade,
          sample
        }
      }

      return item
    })

    setData(newData)
    setSamplesToBeShipment(newData)
    onSubmitSampleShipment()
  }

  useEffect(() => {
    onGetSampleShipment()
  }, [])

  return (
    <Box px={4} py={2.5}>
      <HeaderTitleWide
        backUrl={`/sample-shipments`}
        breadcrumb={t('common.backTo', { menu: t('menu.sampleShipments') })}
        title={`${t('shipment.shipment')} #${shipmentInformation?.shipmentNumber ?? ''}`}
        rightContent={renderButtons}
      />

      {isMobile ? renderMobile : renderWeb}

      <ApproveReject
        id={params.id}
        transactionItemId={transactionItemId}
        setTransactionItemId={setTransactionItemId}
        confirmApproveTitle={t('shipment.confirmApprove.title3')}
        confirmApproveDescription={t('shipment.confirmApprove.description3')}
        confirm={t('common.submit')}
        openQcReason={openQcReason}
        setOpenQcReason={setOpenQcReason}
        openConfirmApprove={openConfirmApprove}
        setOpenConfirmApprove={setOpenConfirmApprove}
        openRejectModal={openRejectModal}
        setOpenRejectModal={setOpenRejectModal}
        onSuccess={onGetSampleShipment}
      />
      <ModalMessage
        open={openSuccess}
        onSubmit={() => {
          setOpenSuccess(false)
          onGetSampleShipment()
        }}
        description={descriptionSuccess}
        buttonText={t('shipment.button.backToShipmentPage')}
      />
      <ShipmentFulfillModal
        id={params.id}
        shipment={shipmentInformation}
        open={openShipmentFulfillModal}
        onClose={() => setOpenShipmentFulfillModal(false)}
        onSubmit={() => {
          setDescriptionSuccess(t('shipment.success.successfullyFulfilledShipment'))
          setOpenSuccess(true)
        }}
      />
      <SendEmailModal
        id={params.id}
        open={openEmailModal}
        onClose={() => setOpenEmailModal(false)}
        onSubmit={() => {
          setDescriptionSuccess(t('shipment.success.successfullySentEmail'))
          setOpenSuccess(true)
        }}
      />
      <ShipmentInfoModal
        id={params.id}
        shipment={shipmentInformation}
        open={openShipmentInfoModal}
        onClose={() => setOpenShipmentInfoModal(false)}
        setShipment={(shipmentInfo) => setSampleShipmentsValue('shipmentInformation', shipmentInfo)}
        onSubmit={() => {
          setOpenShipmentInfoModal(false)
          onSubmitSampleShipment()
        }}
      />

      {indexSamples > -1 &&
	      <ModalWrapper open={openPrintLabel} onClose={() => setOpenPrintLabel(false)}>
	        <PrintLabel
	          dataLabels={[JSON.parse(samplesToBeShipment[indexSamples].label)]}
	          urlQrCode={shipmentInformation.urlQrCode}
	        />
	      </ModalWrapper>
      }

      <SubmitOrder
        data={cloneDeep(samplesToBeShipment)}
        open={openSubmitOrder}
        onClose={() => setOpenSubmitOrder(false)}
        onSubmit={onSubmitTheOrder}
        setValue={(attribute,value) => {
          setSampleShipmentsValue(attribute, value)
        }}
      />

      <SampleInformationModal
        open={openSampleModal}
        onClose={() => setOpenSampleModal(false)}
        sample={data[selectedSample]?.sample}
        setSample={onUpdateSample}
      />
    </Box>
  )
})
