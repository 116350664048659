import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef, GridEventListener, useGridApiRef } from "@mui/x-data-grid";
import { observer } from "mobx-react-lite";

import { useStores } from "models";
import {
	simplifiedCommonSelections,
	simplifiedCommonExpectations,
	simplifiedCommonSampleResults
} from "constants/form";
import { flavorDescriptorList } from 'constants/flavor-descriptors'

import { useStyles } from "./simplified-common.styles";
import { EditSampleModal } from "./edit-sample-modal";
import { centered, colors, theme } from 'assets';
import { SimplifiedCommonDefaultSelectInput, SimplifiedCommonDescriptorInput, SimplifiedCommonSelectInput, SimplifiedCommonStickyColumn, SimplifiedCommonTextInput } from './simplified-common-input';
import { isMobile } from 'react-device-detect';
import { Portal } from '@mui/base'
import { useNavigate } from 'react-router-dom';

const getCellId = (field: string, id: string) => `${field}-${id}`;

const ColumnWidths = {
	desktop: {
		'sample': 200,
		'sampleReference': 150,
		'totalScore': 100,
		'flavorDescriptors': 400,
		'acidityNote': 200,
		'sweetnessNote': 200,
		'physicalAspectDescription': 200,
		'physicalAspectRate': 200,
		'overallNote': 200,
		'result': 200,
	},
	mobile: {
		'sample': 120,
		'sampleReference': 120,
		'totalScore': 100,
		'flavorDescriptors': 150,
		'acidityNote': 120,
		'sweetnessNote': 120,
		'physicalAspectDescription': 120,
		'physicalAspectRate': 120,
		'overallNote': 120,
		'result': 120,
	}
}

const SimplifiedCommonUsing = () => {
	const { t } = useTranslation();
	return (
		<Stack direction='row' spacing={1}>
			<Typography>
				{t('sampleScore.currentlyUsing')}
			</Typography>
			<Typography fontWeight='bold' color={colors.primary.main}>
				{t('options.cuppingProtocol.simplifiedCommon')}
			</Typography>
		</Stack>
	)
}

interface SimplifiedCommonProps {
	checkTotalScore: () => void,
	backUrl: string,
}

export const SimplifiedCommon = observer((props: SimplifiedCommonProps) => {
	const { checkTotalScore, backUrl } = props;
	const { t } = useTranslation()
	const classes = useStyles()
	const apiRef = useGridApiRef();
	const navigate = useNavigate();
	const [rows, setRows] = useState<any[]>(() => [])
	const [openEditSampleModal, setOpenEditSampleModal] = useState(false)

	const {
		sampleStore: { samples }
	} = useStores()

	const onClickReturn = () => {
		navigate(backUrl);
	}

	const processRowUpdate = (newRow) => {
		setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));

		const sample = samples.find(s => s?.id === newRow.id)
		if (!sample) return

		sample.selectedScore.setValue('name', newRow.name)
		sample.selectedScore.setValue('totalScore', newRow.totalScore)
		sample.selectedScore.setValue('flavorDescriptors', newRow.flavorDescriptors)
		sample.selectedScore.setValue('acidityNote', newRow.acidityNote)
		sample.selectedScore.setValue('sweetnessNote', newRow.sweetnessNote)
		sample.selectedScore.setValue('physicalAspectDescription', newRow.physicalAspectDescription)
		sample.selectedScore.setValue('physicalAspectRate', newRow.physicalAspectRate)
		sample.selectedScore.setValue('overallNote', newRow.overallNote)
		sample.selectedScore.setValue('result', newRow.result)
	};

	const updateSampleReference = () => {
		setRows(prevRows => {
			const newRows = [...prevRows]
			newRows.forEach(row => {
				const sample = samples.find(s => s?.id === row.id)
				if (!sample) return

				row.sampleReference = sample.sampleReference;
				row.name = sample.name;
			})

			return newRows
		})
	}
	const columnWidths = isMobile ? ColumnWidths.mobile : ColumnWidths.desktop;
	const stickyColumns: GridColDef[] = [
		{ field: 'id' },
		{
			field: 'sample',
			headerName: t('sampleScore.sample'),
			width: columnWidths['sample'],
			renderCell: (params) => <SimplifiedCommonStickyColumn params={params} />,
		},
		{
			field: 'sampleReference',
			headerName: t('sample.sampleReference'),
			width: columnWidths['sampleReference'],
			renderCell: (params) => <Box fontSize={12}>{params.value ? params.value : '-'}</Box>
		},
	]

	const commonSelections = simplifiedCommonSelections.filter(item => item !== 'Phenol');

	const columns: GridColDef[] = [
		{ field: 'id' },
		{
			field: 'totalScore',
			headerName: t('descriptor.score'),
			editable: true,
			type: 'number',
			width: columnWidths['totalScore'],
			renderCell: (params) =>
				<SimplifiedCommonTextInput params={params} editingCellId={editingCellId} handleChange={handleChange} type='number' />
		},
		{
			field: 'flavorDescriptors',
			headerName: t('descriptor.flavor'),
			editable: true,
			type: 'singleSelect',
			valueOptions: flavorDescriptorList,
			width: columnWidths['flavorDescriptors'],
			renderCell: () => <SimplifiedCommonDefaultSelectInput />,
			renderEditCell: (params) =>
				<SimplifiedCommonDescriptorInput params={params} options={flavorDescriptorList} />,
		},
		{
			field: 'acidityNote',
			headerName: t('descriptor.acidity'),
			editable: true,
			type: 'singleSelect',
			valueOptions: commonSelections,
			width: columnWidths['acidityNote'],
			renderCell: () => <SimplifiedCommonDefaultSelectInput />,
			renderEditCell: (params) =>
				<SimplifiedCommonSelectInput params={params} options={commonSelections} />,
		},
		{
			field: 'sweetnessNote',
			headerName: t('descriptor.sweetness'),
			editable: true,
			type: 'singleSelect',
			valueOptions: commonSelections,
			width: columnWidths['sweetnessNote'],
			renderCell: () => <SimplifiedCommonDefaultSelectInput />,
			renderEditCell: (params) =>
				<SimplifiedCommonSelectInput params={params} options={commonSelections} />,
		},
		{
			field: 'physicalAspectDescription',
			headerName: t('descriptor.physicalAspectDescription'),
			editable: true,
			width: columnWidths['physicalAspectDescription'],
			renderCell: (params) =>
				<SimplifiedCommonTextInput params={params} editingCellId={editingCellId} handleChange={handleChange} />
		},
		{
			field: 'physicalAspectRate',
			headerName: t('descriptor.physicalAspectRate'),
			editable: true,
			type: 'singleSelect',
			valueOptions: simplifiedCommonExpectations,
			width: columnWidths['physicalAspectRate'],
			renderCell: () => <SimplifiedCommonDefaultSelectInput />,
			renderEditCell: (params) =>
				<SimplifiedCommonSelectInput params={params} options={simplifiedCommonExpectations} />,
		},
		{
			field: 'overallNote',
			headerName: t('descriptor.overallExpectation'),
			editable: true,
			type: 'singleSelect',
			valueOptions: simplifiedCommonExpectations,
			width: columnWidths['overallNote'],
			renderCell: () => <SimplifiedCommonDefaultSelectInput />,
			renderEditCell: (params) =>
				<SimplifiedCommonSelectInput params={params} options={simplifiedCommonExpectations} />,
		},
		{
			field: 'result',
			headerName: t('descriptor.sampleResult'),
			editable: true,
			type: 'singleSelect',
			valueOptions: simplifiedCommonSampleResults,
			width: columnWidths['result'],
			renderCell: () => <SimplifiedCommonDefaultSelectInput />,
			renderEditCell: (params) =>
				<SimplifiedCommonSelectInput params={params} options={simplifiedCommonSampleResults} />,
		},
	]

	const handleCellKeyDown = (params, event) => {
		if (event.key === 'Tab') {
			event.preventDefault(); // Prevent the default tab behavior

			const { id: rowId, field } = params; // Current cell's row and field
			const columnIndex = columns.findIndex((col) => col.field === field);

			let nextColumnIndex;
			let nextRowId = rowId;

			if (event.shiftKey) {
				// Shift + Tab: Move to the previous cell
				nextColumnIndex = (columnIndex - 1 + columns.length) % columns.length;
				if (nextColumnIndex === columns.length - 1) {
					// Move to the previous row if we are at the first column
					nextRowId = rowId - 1 < rows[0].id ? rows[rows.length - 1].id : rowId - 1;
				}
			} else {
				// Tab: Move to the next cell
				nextColumnIndex = (columnIndex + 1) % columns.length;
				if (nextColumnIndex === 0) {
					// Move to the next row if we are at the last column
					nextRowId = rowId + 1 > rows.length ? rows[0].id : rowId + 1;
				}
			}

			const nextField = columns[nextColumnIndex].field;
			apiRef.current.setCellFocus(nextRowId, nextField);
		}
	};

	const [editingCellId, setEditingCellId] = useState<string | null>(null);

	const handleCellClick: GridEventListener<'cellClick'> = (params) => {
		const { id, field } = params;
		setEditingCellId(getCellId(field, id as string));
	};

	const handleChange = (id: number, field: string, value: string) => {
		setRows((prevRows) =>
			prevRows.map((row) =>
				row.id === id ? { ...row, [field]: value } : row
			)
		);
	};

	useEffect(() => {
		setRows(samples.map(sample => ({
			id: sample?.id!,
			sampleUniqueNumber: sample?.sampleUniqueNumber,
			name: sample?.name,
			sampleReference: sample?.sampleReference,
			totalScore: sample?.selectedScore.totalScore,
			flavorDescriptors: sample?.selectedScore.flavorDescriptors,
			acidityNote: sample?.selectedScore.acidityNote,
			sweetnessNote: sample?.selectedScore.sweetnessNote,
			physicalAspectDescription: sample?.selectedScore.physicalAspectDescription,
			physicalAspectRate: sample?.selectedScore.physicalAspectRate,
			overallNote: sample?.selectedScore.overallNote,
			result: sample?.selectedScore.result,
		})))
	}, [])

	const stickyGridWidth = columnWidths['sample'] + columnWidths['sampleReference'];
	return (
		<>
			<Box>
				{
					isMobile ? <Box px={2} pt={1} pb={2}><SimplifiedCommonUsing /></Box> :
						<Stack direction='row' justifyContent='space-between' alignItems='end' marginBottom={theme.spacing(4)}>
							<SimplifiedCommonUsing />
							<Button variant='contained' onClick={() => setOpenEditSampleModal(true)}>
								{t('sample.button.editSample')}
							</Button>
						</Stack>
				}

				<Box display='flex' width='100%' position="relative">
					<Box width={stickyGridWidth + 5} className={classes.stickyGrid}>
						<DataGrid
							disableColumnResize
							autoHeight
							rows={rows}
							columns={stickyColumns}
							processRowUpdate={processRowUpdate}
							hideFooter
							disableColumnMenu
							disableColumnSorting
							rowHeight={60}
							initialState={{
								columns: {
									columnVisibilityModel: {
										id: false,
									},
								},
							}}
							autosizeOptions={{
								includeHeaders: true,
								includeOutliers: true,
							}}
							className={classes.dataGrid}
							onProcessRowUpdateError={() => null}
							// apiRef={apiRef}
							onCellKeyDown={handleCellKeyDown}
							onCellClick={handleCellClick}
						/>
					</Box>
					<Box flex='1' overflow='auto' left={stickyGridWidth} className={classes.scrollableGrid}>
						<DataGrid
							disableColumnResize
							autoHeight
							rows={rows}
							columns={columns}
							processRowUpdate={processRowUpdate}
							hideFooter
							disableColumnMenu
							disableColumnSorting
							rowHeight={60}
							initialState={{
								columns: {
									columnVisibilityModel: {
										id: false,
									},
								},
							}}
							autosizeOptions={{
								includeHeaders: true,
								includeOutliers: true,
							}}
							className={classes.dataGrid}
							onProcessRowUpdateError={() => null}
							apiRef={apiRef}
							onCellKeyDown={handleCellKeyDown}
							onCellClick={handleCellClick}
						/>
					</Box>
				</Box>
				<EditSampleModal
					open={openEditSampleModal}
					setOpen={setOpenEditSampleModal}
					updateSampleReference={updateSampleReference}
				/>
			</Box>
			{isMobile ? <Portal>
				<Box sx={{ mb: 5, textAlign: 'right', mt: theme.spacing(4) }} className={classes.buttonMobileWrapper}>
					<Button variant='contained' onClick={() => setOpenEditSampleModal(true)}>
						{t('sample.button.editSample')}
					</Button>
					<Box sx={{...centered, mt: theme.spacing(1.5) }}>
						<Button variant='outlined' sx={{ mr: 1.5 }} onClick={onClickReturn}>
							{t('common.return')}
						</Button>
						<Button variant='contained' onClick={checkTotalScore} >
							{t('sampleScore.reviewAndSubmit')}
						</Button>
					</Box>
				</Box>
			</Portal> : null}
		</>
	);
});
