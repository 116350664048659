import React from 'react';
import { Autocomplete, Box, IconButton, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid";
import { colors } from 'assets';
import { flatListDescriptors } from 'utils';
import { isObject, upperFirst } from 'lodash';
import {
	IconArrowUp, IconArrowDown
} from 'assets/images'

const getCellId = (field: string, id: string) => `${field}-${id}`;

interface SimplifiedCommonStickyColumnProps {
	params: GridRenderCellParams
}

export const SimplifiedCommonStickyColumn: React.FC<SimplifiedCommonStickyColumnProps> = (props: SimplifiedCommonStickyColumnProps) => {
	const { t } = useTranslation()
	const { params } = props;
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				overflow: 'hidden',
				width: '100%',
				height: '100%',
				justifyContent: 'center',
			}}
		>
			<Typography
				fontSize={12}
				fontWeight='500'
				sx={{
					overflow: 'hidden',
					display: '-webkit-box',
					WebkitBoxOrient: 'vertical',
					WebkitLineClamp: 2,
					lineHeight: '1.2',
					textOverflow: 'ellipsis',
					whiteSpace: 'normal',
				}}
			>
				{params.row.name}
			</Typography>
			<Typography fontSize={12} color={colors.primary.main}>
				{params.row.sampleUniqueNumber}
			</Typography>
		</Box>
	)
};

interface SimplifiedCommonTextInputProps {
	params: GridRenderCellParams,
	editingCellId: string | null,
	handleChange: any,
	type?: string,
}

export const SimplifiedCommonTextInput: React.FC<SimplifiedCommonTextInputProps> = (props: SimplifiedCommonTextInputProps) => {
	const { t } = useTranslation()
	const { params, editingCellId, handleChange, type } = props;
	const { id, field } = params;
	const cellId = getCellId(field, id as string);
	if (editingCellId === cellId) {
		return (
			<TextField
				sx={{
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							border: 'none',
						},
						'& input': {
							padding: 0,
						},
					},
				}}
				InputProps={{
					style: {
						border: 'none',
						height: '58px',
						fontSize: '12px',
						marginLeft: '-1px',
					},
				}}
				value={params.value ?? ''}
				onChange={(e) => handleChange(params.id as number, params.field, e.target.value)}
				placeholder={t('placeholder.writeHere')}
				variant="outlined"
				autoFocus
				fullWidth
				type={type ?? 'text'}
			/>
		)
	}
	return (
		params.value ?
			<Box fontSize={12} flex={1} alignItems='center'>{params.value}</Box> :
			<Box fontSize={12} flex={1} alignItems='center' textAlign='left' color={'#CBCED1'}>{t('placeholder.writeHere')}</Box>
	)
};

export const SimplifiedCommonDefaultSelectInput: React.FC = () => {
	const { t } = useTranslation();
	return (
		<Box flexDirection='row' display='flex' flex={1} alignItems='center' fontSize={12} color={'#CBCED1'} justifyContent='space-between'>
			{t('placeholder.select')}
			<IconArrowDown />
		</Box>
	)
};

interface SimplifiedCommonDescriptorInputProps {
	params: GridRenderCellParams,
	options: any[],
}

export const SimplifiedCommonDescriptorInput: React.FC<SimplifiedCommonDescriptorInputProps> = (props: SimplifiedCommonDescriptorInputProps) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const apiRef = useGridApiContext();
	const { params, options } = props;
	const { id, field, value, hasFocus } = params;
	const valueOptions = (flatListDescriptors(options) || []).map((item) => ({
		id: item.id,
		value: item.label,
		label: upperFirst(item.label),
	}));
	const handleValueChange = (event, newValue) => {
		const selectedValues = newValue.map((item) =>
			typeof item === 'string' ? item : item.value
		);

		apiRef.current.setEditCellValue({ id, field, value: selectedValues }, event);
	};

	return (
		<Box display="flex" alignItems="center" width="100%" position='relative'>
			<Autocomplete
				open={isOpen}
				disableClearable
				sx={{
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							border: 'none',
						},
						'& input': {
							padding: 0,
						},
						fontSize: '12px',
						paddingTop: '14px'
					},
				}}
				multiple
				fullWidth
				onChange={handleValueChange}
				options={valueOptions}
				renderTags={(tagValue, getTagProps) =>
					tagValue.map((option, index) => {
						return (
							<>{index > 0 ? ', ' : ''}{option.label}</>
						);
					})
				}
				value={value ? value.map((val) => valueOptions.find((opt) => opt.value === val) || val) : []}
				getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
				isOptionEqualToValue={(o, v) => o.value == v.value}
				renderInput={(params) => (
					<TextField {...params} autoFocus={hasFocus} />
				)}
				onOpen={() => setIsOpen(true)} // Track dropdown state
				onClose={() => setIsOpen(false)}
				componentsProps={{
					popupIndicator: {
						sx: { display: 'none' }, // Custom logic for the indicator
					},
				}}
			/>
			<Box position='absolute' right={0} top={0}>
				<IconButton onClick={() => setIsOpen((prev) => !prev)}>
					{isOpen ? <IconArrowUp /> : <IconArrowDown />}
				</IconButton>
			</Box>
		</Box>
	)
};

interface SimplifiedCommonSelectInputProps {
	params: GridRenderCellParams,
	options: string[] | { value: number | string, label: string }[],
}

export const SimplifiedCommonSelectInput: React.FC<SimplifiedCommonSelectInputProps> = (props: SimplifiedCommonSelectInputProps) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const apiRef = useGridApiContext();
	const { params, options } = props;
	const { id, value, field, hasFocus, colDef } = params;

	const valueOptions = options.map((option: string | { value: number | string, label: string }) => ({
		value: isObject(option) ? option.value: option,
		label: isObject(option) ? option.label: option,
	}))
	valueOptions.unshift({ value: '', label: '' })

	const handleValueChange = (event, item) => {
		const newValue = item?.value || '';
		apiRef.current.setEditCellValue({ id, field, value: newValue });
	};

	return (
		<Box display="flex" alignItems="center" width="100%" position='relative'>
			<Autocomplete
				open={isOpen}
				disableClearable
				sx={{
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							border: 'none',
						},
						'& input': {
							padding: 0,
						},
						fontSize: '12px',
						paddingTop: '14px'
					},
				}}
				fullWidth
				value={valueOptions.find(option => option.value === value) || valueOptions[0]}
				onChange={handleValueChange}
				options={valueOptions}
				getOptionLabel={(option) => option.label}
				isOptionEqualToValue={(o, v) => o.value == v.value}
				renderInput={(params) => (
					<TextField {...params} autoFocus={hasFocus} />
				)}
				onOpen={() => setIsOpen(true)} // Track dropdown state
				onClose={() => setIsOpen(false)}
				componentsProps={{
					popupIndicator: {
						sx: { display: 'none' }, // Custom logic for the indicator
					},
				}}
			/>
			<Box position='absolute' right={0} top={0}>
				<IconButton onClick={() => setIsOpen((prev) => !prev)}>
					{isOpen ? <IconArrowUp /> : <IconArrowDown />}
				</IconButton>
			</Box>
		</Box>
	)
};

