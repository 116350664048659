import React from 'react'
import moment from "moment";
import {useTranslation} from "react-i18next";
import {Chip, Grid, Typography} from "@mui/material";

import { colors } from "assets";
import {optionsTranslation} from "utils";

export const ShipmentInformation = (props) => {
  const { t } = useTranslation()
  const {
    customer, recipient, streetAddress, streetAddress2, city, state, country, zipCode,
    isUrgentDelivery, requestedByEmail, fulfilledOn, recipientEmails, traderEmails, qualityControlEmails, approvalEmails,
    trackingNumber, courierName, fulfillmentStatus, requestedDate,
    thirdPartyName, thirdPartyEmail, estimatedTimeArrival, sampleOrderNumber, phone, additionalDeliveryInstructions,
    deliveryInformation, additionalInstructions
  } = props.shipmentInformation
  const shipmentInformation = [
    {
      label: t('shipmentOrderForm.customer'),
      value: customer
    },
    {
      label: t('shipmentOrderForm.recipient'),
      value: recipient
    },
    {
      label: t('shipmentOrderForm.streetAddress'),
      value: streetAddress,
      grid: 6
    },
	  {
		  label: t('shipmentOrderForm.phoneNumber'),
		  value: phone,
		  grid: 6
	  },
    {
      label: t('shipmentOrderForm.streetAddress2'),
      value: streetAddress2,
      grid: 12
    },
    {
      label: t('shipmentOrderForm.city'),
      value: city
    },
    {
      label: t('shipmentOrderForm.state'),
      value: state
    },
    {
      label: t('shipmentOrderForm.country'),
      value: country
    },
    {
      label: t('shipmentOrderForm.zipCode'),
      value: zipCode
    },
    {
      label: t('shipmentOrderForm.trackingNumber'),
      value: trackingNumber
    },
    {
      label: t('shipmentOrderForm.courier'),
      value: courierName
    }
  ]

  const otherInformation = [
    {
      label: t('shipmentOrderForm.sampleOrderNumber'),
      value: sampleOrderNumber,
      grid: 6
    },
    {
      label: t('shipmentOrderForm.status'),
      value: optionsTranslation('fulfillmentStatus', fulfillmentStatus)
    },
    {
      label: t('shipmentOrderForm.requestDate'),
      value: requestedDate && moment(requestedDate).format('L')
    },
    {
      label: t('shipmentOrderForm.thirdPartyName'),
      value: thirdPartyName ?? 'N/A'
    },
    {
      label: t('shipmentOrderForm.thirdPartyEmail'),
      value: thirdPartyEmail ?? 'N/A',
      isEmail: true
    },
    {
      label: t('shipmentOrderForm.urgentDelivery'),
      value: optionsTranslation('boolean', isUrgentDelivery)
    },
    {
      label: t('shipmentOrderForm.requestBy'),
      value: requestedByEmail
    },
    {
      label: t('shipmentOrderForm.eta'),
      value: estimatedTimeArrival ? moment(estimatedTimeArrival).format('L') : '-'
    },
    {
      label: t('shipmentOrderForm.fulfillmentDate'),
      value: fulfilledOn ? moment(fulfilledOn).format('L') : '-'
    },
  ]

  const notificationInformation = [
    {
      label: t('shipmentOrderForm.recipient'),
      value: recipientEmails,
      isEmail: true
    },
    {
      label: t('shipmentOrderForm.trader'),
      value: traderEmails,
      isEmail: true
    },
    {
      label: t('shipmentOrderForm.qc'),
      value: qualityControlEmails,
      isEmail: true
    },
    {
      label: t('shipmentOrderForm.sampleApprovalNotification'),
      value: approvalEmails,
      isEmail: true
    },
    {
      label: t('shipmentOrderForm.additionalDeliveryInstructions'),
      value: additionalDeliveryInstructions
    },
    {
      label: t('shipmentOrderForm.additionalInstruction'),
      value: additionalInstructions
    }
  ]

  const renderInformation = (label, value, grid, isEmail?) => {
    return (
      <Grid item key={label} xs={grid}>
        <Typography variant="body2">{label}</Typography>
        {!value && <Typography variant="body2" fontWeight={600} color="primary">{isEmail ? 'N/A' : '-'}</Typography>}

        {(isEmail && value) ?
          value.split(',')?.map(email => {
            if (email) {
              return <Chip key={email} label={email.trim()} sx={{ color: colors.primary.main, background: colors.primary.o25, fontWeight: 600 }} />
            }
          })
        : (
          <Typography
            variant="body2"
            fontWeight={600}
            color="primary"
            sx={{ whiteSpace: 'pre-line' }}
          >
            {value}
          </Typography>
        )}
      </Grid>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" mb={1}>{t('shipmentOrderForm.shipmentInformation')}</Typography>
        <Grid container spacing={2} mb={2}>
          {shipmentInformation.map((item) => {
            return renderInformation(item.label, item.value, item.grid ?? 6)
          })}
        </Grid>

        <Typography variant="h6" mb={1}>{t('shipmentOrderForm.otherInformation')}</Typography>
        <Grid container spacing={2} mb={2}>
          {otherInformation.map((item) => {
            return renderInformation(item.label, item.value, item.grid || 6, item.isEmail)
          })}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" mb={1}>{t('shipmentOrderForm.notificationInformation')}</Typography>
        <Grid container spacing={2} mb={2}>
          {notificationInformation.map((item) => {
            return renderInformation(item.label, item.value, 12, item.isEmail)
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}
