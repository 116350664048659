/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withGeneralError, withReset, withRootStore, withEnvironment, withSetValue
} from "models/extensions"
import {
  ShipmentOrderFormModel,
  shipmentOrderFormPreProcessor,
  shipmentOrderFormProps
} from "models/shipment-order-form";

import {
  sampleOrderDefault,
  sampleProps,
  sampleTransactionItemProps,
  shipmentInformationProps, subOrderProps,
} from "./sample-order.props"
import {withSampleOrderActions} from "./sample-order-action";
import {withSampleOrderViews} from "./sample-order.view";
import {filterModel, filterModelCancelled, filterModelFulfilled, filterModelInProgress, filterModelUnfulfilled} from "./filter-model";

/**
 * Model description here for TypeScript hints.
 */
export const SampleOrderStoreModel = types
  .model("SampleOrderStore")
  .props({
    samplesToBeShipment: types.optional(
      types.array(types.model(sampleProps)),
      []
    ),
    shipmentInformation: types.optional(types.model(shipmentInformationProps), shipmentOrderFormPreProcessor({})),
    sampleOrders: types.optional(types.array(types.model(sampleOrderDefault)), []),
    sampleOrderInProgress: types.optional(types.array(types.model(sampleOrderDefault)), []),
    sampleOrderCancelled: types.optional(types.array(types.model(sampleOrderDefault)), []),
    sampleOrderUnfulfilled: types.optional(types.array(types.model(sampleOrderDefault)), []),
    sampleOrderFulfilled: types.optional(types.array(types.model(sampleOrderDefault)), []),
    page: types.optional(types.number, 1),
    totalPage: types.optional(types.number, 0),
    sort: types.optional(types.string, ''),
    filter: types.optional(filterModel, {}),
    filterInProgress: types.optional(filterModelInProgress, {}),
    filterCancelled: types.optional(filterModelCancelled, {}),
    filterUnfulfilled: types.optional(filterModelUnfulfilled, {}),
    filterFulfilled: types.optional(filterModelFulfilled, {}),
    pageInProgress: types.optional(types.number, 1),
    totalPageInProgress: types.optional(types.number, 0),
    pageCancelled: types.optional(types.number, 1),
    totalPageCancelled: types.optional(types.number, 0),
    pageUnfulfilled: types.optional(types.number, 1),
    totalPageUnfulfilled: types.optional(types.number, 0),
    pageFulfilled: types.optional(types.number, 1),
    totalPageFulfilled: types.optional(types.number, 0),
  })
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withEnvironment)
  .extend(withSampleOrderActions)
  .extend(withSampleOrderViews)

type SampleOrderStoreType = Instance<typeof SampleOrderStoreModel>
export interface SampleOrderStore extends SampleOrderStoreType {}
type SampleOrderStoreSnapshotType = SnapshotOut<typeof SampleOrderStoreModel>
export interface SampleOrderStoreSnapshot extends SampleOrderStoreSnapshotType {}
