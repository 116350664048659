import React, { useMemo } from "react";
import {camelCase} from "lodash";
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {HelpOutline} from "@mui/icons-material";
import {
  FormControl,
  FormControlProps,
  FormLabel,
  IconButton,
  MenuItem,
  Popover,
  Select,
  SelectProps, Typography
} from "@mui/material";

import { useStores } from 'models'
import {cuppingProtocols} from "constants/form"
import {CuppingProtocol} from "constants/constant"

interface CuppingProtocolProps {
  label?: string
  formControlProps?: FormControlProps
  selectProps?: SelectProps
  comboCuppingFlag?: boolean
}

export const CuppingProtocolForm: React.FC<CuppingProtocolProps> = observer((props: CuppingProtocolProps) => {
  const { t } = useTranslation()
  const { companyStore: { showSucafinaTasting, showCommercial, showSoluble, isSucafinaFeature }} = useStores()
  const { label = t('cuppingSession.cuppingProtocol'), formControlProps, selectProps, comboCuppingFlag } = props

  const [anchorComboCupping, setAnchorComboCupping] = React.useState<HTMLButtonElement | null>(null)
  const openComboCupping = Boolean(anchorComboCupping)

  const filteredCuppingProtocols = useMemo(()=> {
    if (isSucafinaFeature) {
      return cuppingProtocols;
    }
    return cuppingProtocols.filter((item) => item !== 'simplified_common');
  }, [isSucafinaFeature])

  return (
    <FormControl fullWidth margin='normal' {...formControlProps}>
      <FormLabel>
        {label}
        <IconButton
          color='primary'
          onClick={(event) => setAnchorComboCupping(event.currentTarget)}
          sx={{ display: comboCuppingFlag ? 'inline' : 'none' }}
        >
          <HelpOutline />
        </IconButton>

        <Popover
          open={openComboCupping}
          anchorEl={anchorComboCupping}
          onClose={() => setAnchorComboCupping(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          PaperProps={{
            sx: { width: 300 }
          }}
        >
          <Typography sx={{ p: 2 }}>{t('sample.comboCuppingPopover')}</Typography>
        </Popover>
      </FormLabel>
      <Select
        size='small'
        defaultValue=""
        {...selectProps}
      >
        {filteredCuppingProtocols.map(item => {
          if (!showSucafinaTasting && item === CuppingProtocol.SUCAFINA_TASTING) return null;
          if (!showCommercial && item === CuppingProtocol.COMMERCIAL) return null;
          if (!showSoluble && item === CuppingProtocol.SOLUBLE) return null;

          return (
            <MenuItem key={item} value={item}>
              {t(`options.cuppingProtocol.${camelCase(item)}`)}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  );
})
