import {ceil, chain, isEmpty, snakeCase} from "lodash";
import {applySnapshot, flow, getSnapshot, detach} from "mobx-state-tree";
import { ApiResult } from "services/api";
import {
  sampleOrderPreProcessor,
  sampleShipmentListPreProcessor,
} from "./sample-shipment-preprocessor";
import {DATA_PER_PAGE} from "config/env";
import {sampleShipmentPreProcessor} from "models/sample-shipment-store/sample-shipment-preprocessor";
import {SampleShipmentApproval} from "constants/constant";
import {snakeCasePayload} from "utils";

export const withSampleOrderActions = (self: any) => ({
  actions: {
    setSamplesToBeShipment(samples) {
      const samplesToBeShipment = samples.map(sample => sampleShipmentPreProcessor(sample))

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    getSamplesToBeShipment(samples){
      return samples.map(sample => sampleOrderPreProcessor(sample))
    },
    setThirdPartyEmails(emails, fulfillmentType, sampleLocation){
      const samplesToBeShipment = self.samplesToBeShipment.map(sample => {
        if (sample.sampleFulfillmentType === fulfillmentType && sample.sampleLocation === sampleLocation) {
          return {
            ...sample,
            thirdPartyEmail: emails,
            sample: {
              ...sample.sample
            }
          }
        }

        return {
          ...sample,
          sample: {
            ...sample.sample
          }
        }
      })

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    setSubOrderStatus(status, fulfillmentType, sampleLocation){
      const samplesToBeShipment = self.samplesToBeShipment.map(sample => {
        if (sample.sampleFulfillmentType === fulfillmentType && sample.sampleLocation === sampleLocation) {
          return {
            ...sample,
            subOrderStatus: status,
            sample: {
              ...sample.sample
            }
          }
        }

        return {
          ...sample,
          sample: {
            ...sample.sample
          }
        }
      })

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    setSubOrderIsCreatedShipment(fulfillmentType, sampleLocation){
      const samplesToBeShipment = self.samplesToBeShipment.map(sample => {
        if (sample.sampleFulfillmentType === fulfillmentType && sample.sampleLocation === sampleLocation) {
          return {
            ...sample,
            isCreatedShipment: true,
            subOrderStatus: "fulfilled",
            sample: {
              ...sample.sample
            }
          }
        }

        return {
          ...sample,
          sample: {
            ...sample.sample
          }
        }
      })

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    setSubOrderNote(note, fulfillmentType, sampleLocation){
      const samplesToBeShipment = self.samplesToBeShipment.map(sample => {
        if (sample.sampleFulfillmentType === fulfillmentType && sample.sampleLocation === sampleLocation) {
          return {
            ...sample,
            notes: note,
            sample: {
              ...sample.sample
            }
          }
        }

        return {
          ...sample,
          sample: {
            ...sample.sample
          }
        }
      })

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    getSampleOrders: flow(function * () {
      try {
        let filter = {}

        chain(self.filter)
          .omitBy(isEmpty)
          .forEach((value, key) => { filter[`q[${snakeCase(key)}]`] = value})
          .value()

        const params = {
          ...filter,
          "size": DATA_PER_PAGE,
          "page[number]": self.page,
          "sort": self.sort
        }
        const additionalPath = 'sample_order_lists'
        const res: ApiResult = yield self.environment.sampleTransactionApi.all(params, additionalPath)

        if (res.kind === "ok") {
          const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE)

          self.sampleOrders.map(detach);
          self.sampleOrders.replace(
            res.data.map((sampleShipment) =>sampleShipmentListPreProcessor(sampleShipment)
          ));
          self.totalPage = totalPage;
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getSampleOrder: flow(function * (id) {
      try {
				const { name: companyName } = self.rootStore.companyStore
        const res: ApiResult = yield self.environment.sampleTransactionApi.find(id)

        if (res.kind === "ok") {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            shipmentInformation: sampleOrderPreProcessor(res.data),
            samplesToBeShipment: res.data.sampleTransactionItems.map(sample => {
	            let sampleLocation = sample.sampleLocation || ''

	            if (!sample.sampleLocation || !sample.sampleFulfillmentType) {
		            sampleLocation = companyName || ''
	            }

              return {
                ...sampleOrderPreProcessor(sample),
                sampleName: sample.sampleName ?? '',
	              sampleLocation
              }
            })
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    saveSampleShipment: flow(function * () {
      try {
        const payload: any = {
          ...self.shipmentInformation,
          streetAddress_2: self.shipmentInformation.streetAddress2,
          type: "SampleShipment",
          sampleTransactionItemsAttributes: self.samplesToBeShipment.map(sample => {
            return {
              "sample_id": sample.sampleId,
              "sample_type": sample.sampleType,
              "sample_name": sample.sampleName,
              "sample_grade": sample.sampleGrade,
              "sample_location": sample.sampleLocation,
              "sample_fulfillment_type": sample.sampleFulfillmentType,
              "is_approval_needed": sample.isApprovalNeeded,
              "sample_weight": sample.sampleWeight,
              "is_roasted": sample.isRoasted,
              "third_party_email": sample.thirdPartyEmail,
              "sample_warehouse_reference": sample.sampleWarehouseReference,
              "label": sample.label,
              "salesforce_line_item_id": sample.salesforceLineItemId,
	            "delivery_information": sample.deliveryInformation,
              "sample_attributes": snakeCasePayload(sample.sample, ['id', 'country']),
              ...(sample.approvalStatus === SampleShipmentApproval.DECLINED && {
                approval_status: sample.approvalStatus,
                reason: sample.reason
              }),
            }
          })
        }

        yield self.environment.sampleTransactionApi.save(payload)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw error()
      }
    }),
    saveSubOrderSampleShipment: flow(function * (fulfillmentType, sampleLocation) {
      try {
        const payload: any = {
          ...self.shipmentInformation,
          streetAddress_2: self.shipmentInformation.streetAddress2,
          fulfillmentStatus: "unfulfilled",
          type: "SampleShipment",
          sampleTransactionItemsAttributes: self.samplesToBeShipment.filter( data => data.sampleFulfillmentType === fulfillmentType && data.sampleLocation === sampleLocation).map(sample => {
            return {
              "sample_id": sample.sampleId,
              "sample_type": sample.sampleType,
              "sample_name": sample.sampleName,
              "sample_grade": sample.sampleGrade,
              "sample_location": sample.sampleLocation,
              "sample_fulfillment_type": sample.sampleFulfillmentType,
              "is_approval_needed": sample.isApprovalNeeded,
              "sample_weight": sample.sampleWeight,
              "is_roasted": sample.isRoasted,
              "third_party_email": sample.thirdPartyEmail,
              "sample_warehouse_reference": sample.sampleWarehouseReference,
              "label": sample.label,
              "salesforce_line_item_id": sample.salesforceLineItemId,
	            "delivery_information": sample.deliveryInformation,
              "sample_attributes": snakeCasePayload(sample.sample, ['id', 'country']),
              ...(sample.approvalStatus === SampleShipmentApproval.DECLINED && {
                approval_status: sample.approvalStatus,
                reason: sample.reason
              }),
            }
          })
        }

        yield self.environment.sampleTransactionApi.save(payload)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw error()
      }
    }),
    updateSampleOrder: flow(function * (id, payload?) {
      try {
        if (!payload) {
          payload = {
            ...self.shipmentInformation,
            id,
            streetAddress_2: self.shipmentInformation.streetAddress2,
            type: "SampleOrder",
            sampleTransactionItemsAttributes: self.samplesToBeShipment.map(sample => {
              return {
                ...snakeCasePayload(sample, ['sample']),
                "sample_attributes": snakeCasePayload(sample.sample, ['country'])
              }
            })
          }
        }

        const res: ApiResult = yield self.environment.sampleTransactionApi.save(payload)

        if (res.kind === "ok") {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            shipmentInformation: sampleShipmentPreProcessor(res.data),
            samplesToBeShipment: self.getSamplesToBeShipment(res.data.sampleTransactionItems)
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw error()
      }
    }),
    archivedSampleOrders: flow(function * (token) {
      try {
        const payload: any = {id: token, archivedAt: self.archivedAt}
        const res: ApiResult = yield self.environment.sampleTransactionApi.save(payload)

        if (res.kind === "ok") {
          self.archivedAt = ''

          return res.data

        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    cencelSampleOrders: flow(function * (token) {
      try {
        const payload: any = {id: token, fulfillmentStatus: self.fulfillmentStatus}
        const res: ApiResult = yield self.environment.sampleTransactionApi.save(payload)

        if (res.kind === "ok") {

        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw Error()
      }
    }),
    cancelSampleOrderWithReason: flow(function * (token, reason) {
      try {
        const payload: any = {id: token, fulfillmentStatus: self.fulfillmentStatus, reason}
        const res: ApiResult = yield self.environment.sampleTransactionApi.save(payload)

        if (res.kind === "ok") {

        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw Error()
      }
    }),
    bulkDeclineSampleOrders: flow(function * (payload) {
      try {
        let additionalPath = `bulk_update_status_items`
        const res: ApiResult = yield self.environment.sampleTransactionApi.save(payload,{}, additionalPath)

        if (res.kind === "ok") {
          // applySnapshot(self, {
          //   ...getSnapshot(self as object) as any,
          //   samplesToBeShipment: self.samplesToBeShipment.map(sample => {
          //     payload.itemIds.map( data => {
          //       if (sample.uniqueToken === data) {
          //         return {
          //           ...sample,
          //           approvalStatus: payload.approvalStatus,
          //           reason: payload.reason,
          //           sample: {
          //             ...sample.sample
          //           }
          //         }
          //       }
          //     })
          //
          //
          //     return {
          //       ...sample,
          //       sample: {
          //         ...sample.sample
          //       }
          //     }
          //   })
          // })

          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw Error()
      }
    }),
    updateApprovalStatus: flow(function * (id, payload) {
      try {
        let additionalPath = `${id}/approve_transaction_item`
        let res: ApiResult = yield self.environment.sampleTransactionApi.save(payload, {}, additionalPath)

        if (res.kind === "ok") {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            samplesToBeShipment: self.samplesToBeShipment.map(sample => {
              if (sample.uniqueToken === payload.uniqueToken) {
                return {
                  ...sample,
                  approvalStatus: payload.approvalStatus,
                  reason: payload.reason,
                  sample: {
                    ...sample.sample
                  }
                }
              }

              return {
                ...sample,
                sample: {
                  ...sample.sample
                }
              }
            })
          })

          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw error
      }
    }),
    setSamplesToBeShipmentGenerateLabel(index, data) {
      const samplesToBeShipment = self.samplesToBeShipment.map((sample , i) => {
        if(i === index) {
          return {
            ...sample,
            sample: {
              ...sample.sample,
            },
            label: JSON.stringify(data)
          }
        }

        return {
          ...sample,
          sample: {
            ...sample.sample
          }
        }
      })

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    setSamplesToBeShipmentMultiGenerateLabel(data) {
      const samplesToBeShipment = self.samplesToBeShipment.map((sample , i) => {

        if(sample.sample.sampleUniqueNumber === data[1].value) {
          return {
            ...sample,
            sample: {
              ...sample.sample,
            },
            label: JSON.stringify(data)
          }
        }

        return {
          ...sample,
          sample: {
            ...sample.sample
          }
        }
      })

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    setSamplesToBeShipmentSample(index, data) {
      const samplesToBeShipment = self.samplesToBeShipment.map((sample, i) => {
        if(i === index) {
          return {
            ...sample,
            sample: {
              ...sample.sample,
              ...data
            },
          }
        }

        return {
          ...sample,
          sample: {
            ...sample.sample
          }
        }
      })

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        samplesToBeShipment
      })
    },
    getSampleOrderByStatus: flow(function* (type) {
      try {

        let filterKey, pageKey, snapshotKey, totalPageKey;
    
        switch (type) {
          case 'InProgress':
            filterKey = 'filterInProgress';
            pageKey = 'pageInProgress';
            snapshotKey = 'sampleOrderInProgress';
            totalPageKey = 'totalPageInProgress';
            break;
          case 'Cancelled':
            filterKey = 'filterCancelled';
            pageKey = 'pageCancelled';
            snapshotKey = 'sampleOrderCancelled';
            totalPageKey = 'totalPageCancelled';
            break;
          case 'Unfulfilled':
            filterKey = 'filterUnfulfilled';
            pageKey = 'pageUnfulfilled';
            snapshotKey = 'sampleOrderUnfulfilled';
            totalPageKey = 'totalPageUnfulfilled';
            break;
          case 'Fulfilled':
            filterKey = 'filterFulfilled';
            pageKey = 'pageFulfilled';
            snapshotKey = 'sampleOrderFulfilled';
            totalPageKey = 'totalPageFulfilled';
            break;
          default:
            throw new Error(`Unknown type: ${type}`);
        }
    
        let filter = {};
        chain(self[filterKey])
          .omitBy(isEmpty)
          .forEach((value, key) => {
            filter[`q[${snakeCase(key)}]`] = value;
          })
          .value();
    
        const params = {
          ...filter,
          size: DATA_PER_PAGE,
          "page[number]": self[pageKey],
        };
        const additionalPath = 'sample_order_lists';
        const res: ApiResult = yield self.environment.sampleTransactionApi.all(params, additionalPath);
    
        if (res.kind === 'ok') {
          const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE);

            self[snapshotKey].map(detach);
            self[snapshotKey].replace(
              res.data.map((sampleShipment) =>sampleShipmentListPreProcessor(sampleShipment)
            ));
            self[totalPageKey] = totalPage;
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error);
      }
    }),
  }
})
