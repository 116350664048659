
import { types } from 'mobx-state-tree'
import {
  withSetValue,
  withReset
} from "models/extensions"

const filterKanbanModelProps = {
  fulfillmentStatus: types.optional(types.string, ''),
  createdAt: types.optional(types.string, ''),
  country: types.optional(types.string, ''),
  customer: types.optional(types.string, ''),
  shipmentNumber: types.optional(types.string, ''),
}

export const filterModel = types.model({
  thirdPartyEmailBlank: types.optional(types.string, ''),
  fulfillmentStatus: types.optional(types.string, ''),
  createdAt: types.optional(types.string, ''),
  country: types.optional(types.string, ''),
  customer: types.optional(types.string, ''),
  purchaseContractReference: types.optional(types.string, ''),
  salesContractReference: types.optional(types.string, ''),
  shipmentNumber: types.optional(types.string, ''),
  warehouses: types.optional(types.string, ''),
})
  .extend(withSetValue)
  .extend(withReset)

export const filterModelInProgress = types.model(filterKanbanModelProps)
.extend(withSetValue)
.extend(withReset)

export const filterModelCancelled = types.model( filterKanbanModelProps)
  .extend(withSetValue)
  .extend(withReset)

export const filterModelUnfulfilled = types.model(filterKanbanModelProps)
  .extend(withSetValue)
  .extend(withReset)

export const filterModelFulfilled = types.model( filterKanbanModelProps)
  .extend(withSetValue)
  .extend(withReset)